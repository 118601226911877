const metadata = {
  home: {
    title: 'Culta',
    description:
      'O que nos move é a Cultura. Somos uma agência de comunicação e trabalhamos para inovar a forma como se comunica e oferece cultura.',
  },
  services: {
    title: 'Serviços',
    description:
      'Empenhamo-nos na criação de conceitos e estratégias de comunicação e na produção e gestão de conteúdos inovadores. Mas como? Através dos serviços que desenhamos à medida de cada cliente.',
  },
  contacts: {
    title: 'Contactos',
    description:
      'Vamos criar Cultura juntos? Envie-nos as suas ideias, questões e desejos. Trabalhamos sempre para, no mínimo, superar todas as expectativas.',
  },
  blog: {
    title: 'Histórias',
    description:
      'Somos uma agência de comunicação e é nossa missão fazer de cada momento uma história única e inesquecível. Especializados nas indústrias culturais e criativas, damos voz à Cultura.',
  },
  gallery: {
    title: 'Galeria',
    description:
      'São fotografias e criações de todos e de quem quiser. Esta galeria reúne pessoas, lugares, paisagens, património e momentos. Muitos destes são irrepetíveis, mas eternos aos nossos olhos. Esta é mais uma oportunidade de um encontro com a Cultura.',
  },
  portfolio: {
    title: 'Projectos',
    description:
      ' Estes são os projectos com a assinatura da Culta. Em cada um está a nossa dedicação, empenho e criatividade. Colocamos o nosso talento ao serviço da Cultura. Para que, através da comunicação, a Cultura seja de todos e para todos.',
  },
  about: {
    title: 'Sobre a Culta',
    description:
      'Somos uma agência de comunicação e é nossa missão fazer de cada momento uma história única e inesquecível. Comunicar para o desenvolvimento cultural e social, para a sustentabilidade e inclusão, comunicar para abrir portas, criar relação e fazer crescer.',
  },
};

export default metadata;
